<template>
  <div class="UploadMainBody">
    <div class="lable">营业执照（请确保图片清晰可见）</div>
    <div class="myUploadBody">
      <PaUpload
        class="myUpload"
        v-model="from.BusinessLicense"
        :is_required="false"
        :submitType="submitType"
        typename="business_license_gkey"
        placeholder="上传营业执照"
        is_required_text="请上传营业执照"
        Tips="图片大小≤8M，支持PNG.JPG"
      />
    </div>
    <div class="lable">法人身份证<i>*</i>（请确保图片清晰可见)</div>
    <div class="myUploadBody">
      <PaUpload
        class="myUpload"
        v-model="from.CorporateIdentityCardTop"
        typename="id_card_human_page_gkey"
        :is_required="true"
        :submitType="submitType"
        placeholder="上传人像页"
        is_required_text="请上传人像页"
        Tips="图片大小≤8M，支持PNG.JPG"
      />
      <PaUpload
        class="myUpload"
        v-model="from.CorporateIdentityCardBottom"
        typename="id_card_country_page_gkey"
        :is_required="true"
        :submitType="submitType"
        placeholder="上传国徽页"
        is_required_text="请上传国徽页"
        Tips="图片大小≤8M，支持PNG.JPG"
      />
    </div>
    <div class="lable">
      <div class="lable_z">银行账户流水</div>
      <div class="lable_z">Paypal账户余额</div>
    </div>
    <div class="myUploadBody">
      <PaUpload
        class="myUpload"
        v-model="from.BankAccountFlow"
        typename="bank_revenue_gkey"
        :is_required="false"
        :submitType="submitType"
        placeholder="上传银行账户流水"
        is_required_text="请上传银行账户流水"
        Tips="请上传最近3~6个月银行流水截图<br />
        图片大小≤8M，支持PNG.JPG"
      />
      <PaUpload
        class="myUpload"
        v-model="from.Paypal"
        typename="paypal_balance_gkey"
        :is_required="false"
        :submitType="submitType"
        placeholder="上传Paypal账户余额"
        Tips="若有Paypal账户，请上传余额页截图<br />
        图片大小≤8M，支持PNG.JPG"
      />
    </div>
    <div class="lable">
      运营明细（若已有运营中店铺，请上传店铺近1个月内相应明细截图）
    </div>
    <div class="myUploadBody">
      <PaUpload
        class="myUpload"
        v-model="from.OperationDetailsOrder"
        typename="order_detail_gkey"
        :is_required="false"
        :submitType="submitType"
        placeholder="上传订单明细"
        Tips="图片大小≤8M，支持PNG.JPG"
      />
      <PaUpload
        class="myUpload"
        v-model="from.OperationDetailsExpress"
        typename="transport_detail_gkey"
        :is_required="false"
        :submitType="submitType"
        placeholder="上传发货明细"
        Tips="图片大小≤8M，支持PNG.JPG"
      />
    </div>
    <div class="auxiliary">
      <div class="previous" @click="returnPage">
        <i class="el-icon-arrow-left"></i>
        <span>返回</span>
      </div>
      <div class="nextButten" @click="next">下一步</div>
    </div>
  </div>
</template>
<script>
import store from "@/store/index";
import { mapState, mapMutations } from "vuex";
import Pacode from "@/components/basic/Pacode.vue";
import PaInput from "@/components/basic/PaInput.vue";
import PaUpload from "@/components/basic/PaUpload.vue";
import { register } from "@/assets/js/api";
import { handleResponseErrors } from "@/assets/js/utils";
export default {
  store,
  data() {
    return {
      from: {
        BusinessLicense: { value: "", isError: false }, //营业执照  key:business_license_gkey
        CorporateIdentityCardTop: { value: "", isError: false }, //法人身份证(人像)  key:id_card_human_page_gkey
        CorporateIdentityCardBottom: { value: "", isError: false }, //法人身份证（国徽）  key:id_card_country_page_gkey
        BankAccountFlow: { value: "", isError: false }, //银行账户流水  key:bank_revenue_gkey
        Paypal: { value: "", isError: false }, //Paypal账户余额  key:paypal_balance_gkey
        OperationDetailsOrder: { value: "", isError: false }, //运营明细(上传订单明细)  key:order_detail_gkey
        OperationDetailsExpress: { value: "", isError: false } //运营明细(上传发货明细)  key:transport_detail_gkey
      },
      submitType: false
    };
  },
  components: { PaInput, Pacode, PaUpload },
  computed: { ...mapState(["ERFrom"]) },
  methods: {
    ...mapMutations(["upData_ERFrom"]),
    returnPage() {
      let form =
        this.ERFrom || JSON.parse(sessionStorage.getItem("fpp-ERFrom"));
      Object.keys(this.from).forEach(key => {
        form[key] = this.from[key].value;
      });
      this.upData_ERFrom(form);
      this.$router.go(-1);
    },
    next() {
      window.gtag("event", "Click-button-e3", {
        event_category: "register",
        event_label: "enterprise"
      });
      this.submitType = true;
      this.$nextTick(async () => {
        try {
          if (
            Object.values(this.from).every(item => {
              return item.isError != true;
            })
          ) {
            let form =
              this.ERFrom || JSON.parse(sessionStorage.getItem("fpp-ERFrom"));
            Object.keys(this.from).forEach(key => {
              form[key] = this.from[key].value;
            });
            this.upData_ERFrom(form);
            await this.sendForm();
            window.gtag("event", "Click-button-e3-success", {
              event_category: "register",
              event_label: "enterprise"
            });
            window.gtag("event", "sbumit-conformed", {
              event_category: "register",
              event_label: "enterprise"
            });
            this.$router.push({
              path: "/register/success/"
            });
          }
        } catch (error) {
          if (error.toString().match(/auth_failed/)) {
            return;
          }
          console.log(error);
        }
      });
    },
    async sendForm() {
      let seller_id = localStorage.getItem("seller_id");
      let secret_key = localStorage.getItem("secret_key");
      let data = {
        seller_id,
        secret_key,
        business_license_gkey: this.from.BusinessLicense.value,
        id_card_human_page_gkey: this.from.CorporateIdentityCardTop.value,
        id_card_country_page_gkey: this.from.CorporateIdentityCardBottom.value,
        bank_revenue_gkey: this.from.BankAccountFlow.value,
        paypal_balance_gkey: this.from.Paypal.value,
        order_detail_gkey: this.from.OperationDetailsOrder.value,
        transport_detail_gkey: this.from.OperationDetailsExpress.value
      };
      let ref = await register.sendStep3(data);
      if (ref.code != 0) {
        handleResponseErrors(ref.code, ref.msg);
        throw new Error("auth_failed");
        return;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
.UploadMainBody {
  display: flex;
  flex-direction: column;
  .lable {
    min-height: 28px;
    font-size: 20px;
    font-weight: 500;
    color: $black;
    line-height: 28px;
    display: flex;
    justify-content: flex-start;
    .lable_z {
      flex: 1;
    }
    i {
      color: red;
      font-style: normal;
    }
  }
  .myInputType {
    width: 570px;
    margin-top: 20px;
  }

  .myUploadBody {
    padding-bottom: 35px;
    margin-bottom: 35px;
    border-bottom: $greyLiner 2px solid;
    display: flex;
    justify-content: space-between;
    .myUpload {
      margin-top: 20px;
      flex: 1;
    }
  }
  .auxiliary {
    height: 122px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 70px;
    .previous {
      font-size: 18px;
      font-weight: 500;
      color: $green;
      line-height: 25px;
      cursor: pointer;
    }
    .nextButten {
      width: 160px;
      height: 52px;
      background: $green;
      border-radius: 8px;
      font-size: 20px;
      font-weight: 500;
      color: $fff;
      line-height: 52px;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
